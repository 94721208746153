import { debug } from 'debug'
import { up } from 'up-fetch'

import { generateErrorTemplate, getCustomerName, storage } from '@olyslager/global-utilities'

import { CUSTOMER_HEADER_NAME, LOCALSTORAGE_TOKEN_KEY } from '@/constants'

const debugRequest = debug('api-client:authClient:request')
const debugGetAccessToken = debug('api-client:authClient:getAccessToken')
const debugResponse = debug('api-client:authClient:response')
const debugRefresh = debug('api-client:authClient:refreshToken')

const AUTH_URL = '/authentication/token'
const TOKEN_LOGIN_ENDPOINT = '/authentication/tokenlogin'

async function createClient (baseUrl: string) {
  debugRequest('Requesting %s', baseUrl)

  const client = up(fetch, () => ({
    baseUrl,
    headers: {
      'x-oly-subscription': API_MANAGEMENT_SUBSCRIPTION_KEY || ''
    },
    onSuccess: (data, options) => debugResponse(`Request response from ${options.baseUrl} %O`, data),
    onResponseError: async (error, options) => {
      debugResponse(`Request error from ${options.baseUrl} %O`, error)
    }
  }))

  return client
}

export function getAccessToken (): string | null {
  debugGetAccessToken('Reading access token')
  const accessToken = storage.localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)
  if (accessToken === null) {
    return null
  }
  return `Bearer ${accessToken}`
}

export async function refreshAccessToken (customer: string) : Promise<void> {
  try {
    debugRefresh('Refreshing access token')
    const client = await createClient(API_URL_AUTH)

    const response = await client(AUTH_URL, (defaultOptions) => ({
      method: 'GET',
      headers: {
        ...defaultOptions.headers,
        [CUSTOMER_HEADER_NAME]: customer
      }
    }))
    storeAccessToken(response)
    debugRefresh('Refreshed access token')
  } catch (error: unknown) {
    debugRefresh('Refreshing access token failed', error)
    const main = document.querySelector('body>main')
    if (main) {
      main.innerHTML = generateErrorTemplate(new Error('Client error'))
    }
  }
}

function storeAccessToken (accessToken: string) : void {
  storage.localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, accessToken)
}

export async function getAccessTokenWithCredentials (username: string, password: string): Promise<string> {
  const client = await createClient(API_URL_AUTH)

  const response: string = await client(TOKEN_LOGIN_ENDPOINT, (defaultOptions) => ({
    method: 'POST',
    body: {
      Username: username,
      Password: password
    },
    headers: {
      ...defaultOptions.headers,
      [CUSTOMER_HEADER_NAME]: getCustomerName()
    }
  }))

  return response
}
