import { debug } from 'debug'
import { DefaultOptions, up } from 'up-fetch'

import { getCustomerName } from '@olyslager/global-utilities'

import { getAccessToken, refreshAccessToken } from '../clients/authClient'

const debugRequest = debug('api-client:clientFactoryFetch:request')
const debugResponse = debug('api-client:clientFactoryFetch:response')

async function createClient (baseUrl: string) {
  debugRequest('Requesting %s', baseUrl)

  let accessToken = getAccessToken()
  if (accessToken === null) {
    debugRequest('No access token found, getting a new one')
    await refreshAccessToken(getCustomerName())
    accessToken = getAccessToken()
  }

  const client = up(fetch, () => ({
    baseUrl,
    headers: {
      Authorization: accessToken,
      'x-oly-subscription': API_MANAGEMENT_SUBSCRIPTION_KEY || ''
    },
    onSuccess: (data, options) => debugResponse(`Request response from ${options.baseUrl} %O`, data),
    onResponseError: async (error, options) => {
      debugResponse(`Request error from ${options.baseUrl} %O`, error)
      if (error.response && error.response.status === 401) {
        debugResponse('401 Unauthorized, getting new token')
        await refreshAccessToken(getCustomerName())
        // retry
      } else {
        debugResponse('Error without response object')
      }
    }
  }))

  return client
}

export type RequestOptions = {
  body: string | object,
  headers?: object,
  isBlobResponse?: boolean
}

function getFetchOptions (method: 'GET' | 'POST', options: RequestOptions, defaultOptions: DefaultOptions<typeof fetch>) {
  const upfetchOptions = {
    method,
    body: options.body,
    headers: {
      ...defaultOptions.headers,
      ...options.headers
    }
  }

  if (options.isBlobResponse) {
    return {
      ...upfetchOptions,
      parseResponse: (res) => res.blob()
    }
  }
  return upfetchOptions
}

async function get (baseUrl: string, url: string, options: RequestOptions) {
  const client = await createClient(baseUrl)
  const response = await client(url, (defaultOptions) => getFetchOptions('GET', options, defaultOptions))
  return response
}

async function post (baseUrl: string, url: string, options: RequestOptions) {
  const client = await createClient(baseUrl)
  const response = await client(url, (defaultOptions) => getFetchOptions('POST', options, defaultOptions))
  return response
}

export const clientFactory = {
  get,
  post
}
