import { Country } from './types'

export const supportedLicensePlateSearchCountries: Country[] = [
  {
    iso2: 'DK',
    name: 'Denmark'
  },
  {
    iso2: 'FI',
    name: 'Finland'
  },
  {
    iso2: 'DE',
    name: 'Germany'
  },
  {
    iso2: 'FR',
    name: 'France'
  },
  {
    iso2: 'IE',
    name: 'Ireland'
  },
  {
    iso2: 'IT',
    name: 'Italy'
  },
  {
    iso2: 'NL',
    name: 'Netherlands'
  },
  {
    iso2: 'NO',
    name: 'Norway'
  },
  {
    iso2: 'PT',
    name: 'Portugal'
  },
  {
    iso2: 'ES',
    name: 'Spain'
  },
  {
    iso2: 'SE',
    name: 'Sweden'
  },
  {
    iso2: 'CH',
    name: 'Switzerland'
  },
  {
    iso2: 'GB',
    name: 'United Kingdom'
  },
  {
    iso2: 'US',
    name: 'United States of America'
  },
  {
    iso2: 'PL',
    name: 'Poland'
  },
  {
    iso2: 'AT',
    name: 'Austria'
  },
  {
    iso2: 'BE',
    name: 'Belgium'
  }
]
