<script setup lang="ts">
import { PropType, computed, inject } from 'vue'

import { CountryCode, CountryExpanded, countries } from '@olyslager/global-utilities'
import { OlyFlag, OlyIcon } from '@olyslager/shared-components'

import { useSiteConfigurationStore } from '@/stores/siteConfiguration'

import TextOption from '@/components/elements/TextOption.vue'
import LegendBadge from '@/components/legend/LegendBadge.vue'
import world from '@/assets/globe.svg'

defineProps({
  country: {
    type: Object as PropType<CountryExpanded>,
    required: true
  },
  isActive: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits<{(event: 'select', country: CountryExpanded): void}>()

// allowed countries and sitedataconfig from config
const allowedCountries: CountryCode[] | undefined = inject('AllowedCountries')
const allowedTruckCountries: CountryCode[] | undefined = inject('AllowedTruckCountries')
const siteConfigurationStore = useSiteConfigurationStore()

const flagBorderRadius = computed(() => {
  if (siteConfigurationStore.rootProperties.showBorderRadius === false) {
    return 0
  }

  return 4
})
</script>

<template>
  <div class="country-wrapper">
    <div
      class="country-option"
      :class="{ 'active': isActive }"
      @click="emit('select', country)"
    >
      <OlyFlag
        v-if="country.iso2 !== countries.unknownCountry.iso2"
        :country="country.iso2"
        :border-radius="flagBorderRadius"
        shadow
      />
      <OlyIcon
        v-else
        icon-color="#232323"
        :width="28"
        :height="28"
        :icon-url="world"
      />
      <TextOption
        class="country-name"
        :text="country.name"
        :is-active="isActive"
        :active-border="false"
      />
    </div>
    <LegendBadge
      v-if="country.vrn && country.iso2 === 'DE' && allowedCountries?.includes(country.iso2)"
      type="hsntsn"
    />
    <LegendBadge
      v-else-if="country.vrn && country.iso2 === 'AT' && allowedCountries?.includes(country.iso2)"
      type="natcode"
    />
    <LegendBadge
      v-else-if="country.vrn && country.iso2 === 'CH' && allowedCountries?.includes(country.iso2)"
      type="typengenehmigung"
    />
    <LegendBadge
      v-else-if="country.vrn && (allowedCountries?.includes(country.iso2) || allowedTruckCountries?.includes(country.iso2))"
      type="vrn"
    />
    <LegendBadge
      v-if="country.vin && allowedCountries?.includes(country.iso2)"
      type="vin"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@olyslager/shared-components/scss';
.country-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  .country-option {
    display: inline-flex;
    align-items: center;
    border: 1px solid transparent;
    padding-inline: 8px;
    border-radius: var(--border-radius, 8px);

    & :deep(svg) {
      width: 1.8em;
      flex-shrink: 0;
    }

    & :deep(p) {
      padding-inline-end: 0;
    }

    &.active {
      border: 1px solid var(--country-border, var(--color-tertiary));
    }

    .country-name {
      --text: var(--country-text, var(--color-secondary));
    }
  }
}

@include small() {
  .country-wrapper {
    flex-wrap: nowrap;
  }
}
</style>
