// eslint-disable-next-line no-console
console.debug(`${APP_NAME}: v${APP_VERSION}`)

export { configClientGraphQL } from './clients/configClientGraphQL'
export { dataClient } from './clients/dataClient'
export { utilClient } from './clients/utilClient'
export { getAccessTokenWithCredentials } from './clients/authClient'

export type { QueryDocument, QueryError, Variables, QueryOptions } from './factory/clientFactoryApollo'
export type { RequestOptions } from './factory/clientFactoryFetch'

export { gql } from '@apollo/client/core'
